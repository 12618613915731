import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import FlowEntrustComponent from '@/components/flow-manage/flow-entrust/index.vue';
let FlowEntrust = class FlowEntrust extends Vue {
};
FlowEntrust = __decorate([
    Component({
        name: 'FlowEntrust',
        components: {
            FlowEntrustComponent,
        },
    })
], FlowEntrust);
export default FlowEntrust;
